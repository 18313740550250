/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.gelpCx{
  overflow-y: auto !important;
}

.swal2-styled{
  margin-right: 5px !important;
}
.checkStyle {
  position: absolute;
  height: 60px;
  width: 60px;
  padding-top: 12px;
  z-index: 2;
  background-color: rgba(129, 190, 65, .9);
}
.checkStyle1 {
  position: absolute;
  height: 60px;
  width: 60px;
  padding-top: 12px;
  background-color: lightgray;
}
.check1 {
  position: absolute;
  top: 17%;
  left: 6%;
}
.class2{

  /*margin-top: 11px;
  margin-left: 8px;
  position: absolute;
  top: 1%;
  left: -41%;
  background-color: transparent;
  z-index: 1;*/

  position: absolute;
  /* height: 60px; */
  width: 60px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  /* background-color: rgba(129, 190, 65, 0.9); */
  margin-left: 10px;
}
.check3{
  position: absolute;
  top: 17%;
  left: 4.6%;
}
.switch-container {
  position: fixed;
  top: 40%;
  right: 20px; /* Adjust this value to position the switch where you want it */
  transform: translateY(-50%);
  z-index: 9999; /* Make sure the switch appears on top of other elements */
}

.imgStyle{
  width: 50%;
  height: 100%;
  cursor: pointer;
}
.imgStyle2{
  width: 80%;
  height: 250px;
  cursor: pointer;
}

.__react_modal_image__modal_content{
  overflow: scroll !important;
}

.__react_modal_image__medium_img{
  margin-top: 80px !important;
}